.ag-theme-alpine {
  --ag-font-family: inherit;
  --ag-font-size: 14px;
  --ag-header-background-color: #f9fafb;
  --ag-header-foreground-color: #6b7280;
  --ag-header-height: 48px;
  --ag-row-border-color: #e5e7eb;
  --ag-border-color: #e5e7eb;
  --ag-cell-horizontal-padding: 1.5rem;
  --ag-row-hover-color: #f3f4f6;
}

.ag-theme-alpine .ag-header-cell {
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.ag-theme-alpine .ag-cell {
  display: flex;
  align-items: center;
}

.ag-theme-alpine .ag-row {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--ag-row-border-color);
} 
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Timeline styles */
.timeline {
  position: relative;
  padding: 0;
  list-style: none;
}

.timeline:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 2px;
  margin-left: -1.5px;
  background-color: #e9ecef;
}

.timeline > li {
  position: relative;
  margin-bottom: 50px;
}

.timeline > li:before,
.timeline > li:after {
  content: ' ';
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li .timeline-panel {
  float: left;
  position: relative;
  width: 100%;
}

.timeline > li .timeline-image {
  position: absolute;
  z-index: 100;
  left: 50%;
  width: 80px;
  height: 80px;
  margin-left: -40px;
  border: 7px solid #e9ecef;
  border-radius: 100%;
  text-align: center;
  background-color: #fed136;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}

@media (min-width: 768px) {
  .timeline > li {
    min-height: 150px;
  }
  .timeline > li .timeline-panel {
    width: 41%;
  }
  .timeline > li .timeline-image {
    width: 100px;
    height: 100px;
    margin-left: -50px;
  }
}

@media (min-width: 992px) {
  .timeline > li {
    min-height: 150px;
  }
  .timeline > li .timeline-panel {
    width: 44%;
  }
  .timeline > li .timeline-image {
    width: 150px;
    height: 150px;
    margin-left: -75px;
  }
}

/* Post card styles */
.post-card-image {
  background-size: cover;
  background-position: center;
  height: 200px;
}

.post-card-content-link {
  display: block;
  color: inherit;
  text-decoration: none;
}

.post-card-content-link:hover {
  text-decoration: none;
}

.author-profile-image {
  display: block;
}

/* Add these styles to your existing index.css file */

.vertical {
  display: table-cell;
  vertical-align: middle;
}

.inner {
  width: 80%;
}

.page-title {
  margin: 10px 0 10px 0;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: -1px;
}

.page-description {
  margin: 0;
  font-family: 'Merriweather', serif;
  letter-spacing: 0.01rem;
  color: rgba(255, 255, 255, 0.8);
}

.icon-arrow-left:before {
  content: '↓';
  font-size: 2rem;
}

.circle {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Add or update these styles in your index.css file */

.section-heading {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}

.section-subheading {
  font-family: 'Droid Serif', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 400;
}

.text-muted {
  color: #777;
}

/* Add these styles to your existing index.css file */

.post-card {
  @apply bg-white rounded-lg shadow-md overflow-hidden;
}

.post-card-content {
  @apply p-6;
}

.post-card-content-link {
  @apply block text-inherit no-underline;
}

.post-card-header {
  @apply mb-4;
}

.post-card-tags {
  @apply inline-block bg-gray-200 text-gray-700 px-2 py-1 rounded text-sm mr-2 mb-2;
}

.post-card-title {
  @apply text-xl font-semibold mb-2;
}

.post-card-excerpt {
  @apply text-gray-600;
}

.post-card-meta {
  @apply mt-4;
}

.author-profile-image {
  @apply w-8 h-8;
}

.section-heading {
  @apply font-sans font-bold;
}

.section-subheading {
  @apply font-serif italic font-normal;
}

.text-muted {
  @apply text-gray-600;
}

/* Add or update these styles in your index.css file */

.post-card {
  transition: all 0.5s ease;
}

.post-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.post-card-image {
  transition: opacity 0.3s ease;
}

.post-card:hover .post-card-image {
  opacity: 0.8;
}

.post-card-content-link {
  color: inherit;
  text-decoration: none;
}

.post-card-content-link:hover {
  text-decoration: none;
}

/* Add or update these styles in your index.css file */

.post-feed {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
  padding: 40px 0 0 0;
}

.post-card {
  flex: 1 1 300px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 20px 40px;
  min-height: 300px;
  background: #fff center center;
  background-size: cover;
  border-radius: 5px;
  box-shadow:
    rgba(39, 44, 49, 0.06) 8px 14px 38px,
    rgba(39, 44, 49, 0.03) 1px 3px 8px;
  transition: all 0.5s ease;
}

.post-card:hover {
  box-shadow:
    rgba(39, 44, 49, 0.07) 8px 28px 50px,
    rgba(39, 44, 49, 0.04) 1px 6px 12px;
  transition: all 0.4s ease;
  transform: translate3D(0, -1px, 0) scale(1.02);
}

.post-card-image-link {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
}

.post-card-image {
  width: 100%;
  height: 200px;
  background: #c5d2d9 no-repeat center center;
  background-size: cover;
}

.post-card-content-link {
  position: relative;
  flex-grow: 1;
  display: block;
  padding: 25px 25px 0;
  color: #15171a;
}

.post-card-content-link:hover {
  text-decoration: none;
}

.post-card-tags {
  display: block;
  margin-bottom: 4px;
  color: #738a94;
  font-size: 0.8rem;
  line-height: 1.15em;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.post-card-title {
  margin-top: 0;
}

.post-card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.post-card-excerpt {
  font-size: 0.9rem;
}

.post-card-meta {
  padding: 0 25px 25px;
}

.author-profile-image {
  margin-right: 5px;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  object-fit: cover;
}

@media (min-width: 795px) {
  .post-feed .post-card:nth-child(6n + 1):not(.no-image) {
    flex: 1 1 100%;
    flex-direction: row;
  }

  .post-feed .post-card:nth-child(6n + 1):not(.no-image) .post-card-image-link {
    position: relative;
    flex: 1 1 auto;
    border-radius: 5px 0 0 5px;
  }

  .post-feed .post-card:nth-child(6n + 1):not(.no-image) .post-card-image {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .post-feed .post-card:nth-child(6n + 1):not(.no-image) .post-card-content {
    flex: 0 1 357px;
  }

  .post-feed .post-card:nth-child(6n + 1):not(.no-image) h2 {
    font-size: 1.6rem;
  }

  .post-feed .post-card:nth-child(6n + 1):not(.no-image) p {
    font-size: 1.05rem;
    line-height: 1.55em;
  }

  .post-feed
    .post-card:nth-child(6n + 1):not(.no-image)
    .post-card-content-link {
    padding: 30px 40px 0;
  }

  .post-feed .post-card:nth-child(6n + 1):not(.no-image) .post-card-meta {
    padding: 0 40px 30px;
  }
}

/* Custom styles for mini-projects */
@layer components {
  .post-card {
    @apply transition-all duration-300;
  }

  .post-card:hover {
    @apply transform -translate-y-1 shadow-lg;
  }

  .post-card-image-link {
    @apply transition-opacity duration-300;
  }

  .post-card:hover .post-card-image-link {
    @apply opacity-90;
  }
}

/* Existing styles... */

@media (max-width: 768px) {
  .timeline-item {
    margin-left: 0;
    margin-right: 0;
  }
}

/* Optional: Add animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.timeline-item {
  animation: fadeIn 0.5s ease-out forwards;
}

/* Update or add these timeline-specific styles */
@layer components {
  .timeline-image {
    @apply absolute left-1/2 transform -translate-x-1/2 z-10 w-[100px] h-[100px];
  }

  .timeline-image img {
    @apply w-full h-full rounded-full border-[7px] border-gray-200 bg-white object-cover;
  }

  .timeline-panel {
    @apply bg-white p-6 rounded shadow-md;
  }

  .timeline-date {
    @apply text-gray-600 text-lg font-medium;
  }

  .timeline-title {
    @apply text-xl font-bold;
  }

  .timeline-description {
    @apply text-gray-500 text-base;
  }
}

/* Timeline animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.timeline > div {
  animation: fadeIn 0.5s ease-out forwards;
}

/* Timeline responsive adjustments */
@media (max-width: 768px) {
  .timeline-panel {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .timeline-image {
    position: relative;
    margin: 0 auto 1rem;
    left: auto;
    transform: none;
  }
}

/* Update the timeline-specific styles */
@layer components {
  /* Timeline container styles */
  .timeline-container {
    @apply relative;
  }

  /* Timeline vertical line styles */
  .timeline-line {
    @apply absolute h-full w-[2px] bg-gray-200;
  }

  /* Timeline item styles */
  .timeline-item {
    @apply relative flex items-center mb-24; /* Changed from items-start to items-center */
  }

  /* Timeline image styles */
  .timeline-image {
    @apply relative z-10 flex-shrink-0;
  }

  .timeline-image img {
    @apply rounded-full border-[7px] border-gray-200 bg-white object-cover;
  }

  /* Timeline content styles */
  .timeline-content {
    @apply bg-white rounded shadow-md;
  }

  /* Mobile-specific styles */
  @media (max-width: 768px) {
    .timeline-item {
      @apply flex-row;
    }

    .timeline-image {
      @apply ml-0;
    }

    .timeline-image img {
      @apply w-32 h-32; /* Increased from w-20 h-20 */
    }

    .timeline-content {
      @apply ml-8 flex-grow self-center; /* Added self-center */
    }

    .timeline-line {
      @apply left-[60px]; /* Adjusted for larger image */
    }
  }

  /* Desktop-specific styles */
  @media (min-width: 769px) {
    .timeline-image {
      @apply absolute left-1/2 transform -translate-x-1/2;
    }

    .timeline-image img {
      @apply w-[150px] h-[150px]; /* Increased from 100px */
    }

    .timeline-content {
      @apply w-[42%] self-center; /* Added self-center */
    }

    .timeline-line {
      @apply left-1/2 -translate-x-1/2;
    }
  }
}

/* Keep existing timeline animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.timeline-item {
  animation: fadeIn 0.5s ease-out forwards;
}

/* Add these styles if they don't exist */
@layer components {
  input[type='text'],
  input[type='email'],
  input[type='password'] {
    @apply appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm;
  }
}

/* Add these global styles */
html,
body {
  overflow: hidden;
}

/* Optional: Add smooth scrolling to the entire app */
* {
  scroll-behavior: smooth;
}

/* Add these styles for the map */
.map {
  .jvectormap-container {
    border-radius: 0.5rem;
  }

  .jvectormap-zoomin,
  .jvectormap-zoomout {
    display: none;
  }

  .jvectormap-tip {
    background: #fff;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    padding: 0.5rem;
    font-size: 0.875rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    color: #4a5568;
  }
}

/* Add these Leaflet-specific styles */
.leaflet-container {
  width: 100%;
  height: 100%;
  z-index: 1;
}

.leaflet-popup-content-wrapper {
  border-radius: 0.5rem;
}

.leaflet-popup-content {
  margin: 0;
}

/* Add these styles for Leaflet map */
.leaflet-container {
  z-index: 1;
}

.leaflet-pane {
  z-index: 1;
}

.leaflet-top,
.leaflet-bottom {
  z-index: 1;
}

/* Add these styles to hide Leaflet attribution */
.leaflet-control-attribution {
  display: none !important;
}

/* Modern Vibrant Map Styles 2024 */
.leaflet-container {
  background-color: #f8fafc !important;
  font-family: 'Inter', sans-serif !important;
}

/* Custom marker styling */
.custom-marker-icon {
  background: transparent;
  border: none;
}

.marker-pin-wrapper {
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.marker-pin {
  width: 32px;
  height: 32px;
  border-radius: 50% 50% 50% 0;
  background: linear-gradient(45deg, #4f46e5, #7c3aed); /* Vibrant gradient */
  position: relative;
  transform: rotate(-45deg);
  animation: bounce 0.3s ease-in-out;
  box-shadow: 0 4px 12px rgba(79, 70, 229, 0.4);
  z-index: 2;
}

.marker-pin-inner {
  background: white;
  width: 16px;
  height: 16px;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Pulsing effect behind markers */
.marker-pulse {
  position: absolute;
  width: 40px;
  height: 40px;
  background: rgba(79, 70, 229, 0.2);
  border-radius: 50%;
  z-index: 1;
  animation: pulse 2s infinite;
}

/* Popup modernization */
.leaflet-popup-content-wrapper {
  border-radius: 1.25rem !important;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1) !important;
  border: 1px solid rgba(255, 255, 255, 0.8) !important;
  backdrop-filter: blur(8px) !important;
  background: rgba(255, 255, 255, 0.95) !important;
}

.leaflet-popup-tip {
  background: rgba(255, 255, 255, 0.95) !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
}

/* Zoom controls modernization */
.leaflet-control-zoom {
  border: none !important;
  border-radius: 1rem !important;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(8px);
  background: rgba(255, 255, 255, 0.9) !important;
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  background: transparent !important;
  border: none !important;
  color: #4f46e5 !important; /* Indigo */
  width: 36px !important;
  height: 36px !important;
  line-height: 36px !important;
  font-size: 18px !important;
  font-weight: bold !important;
  transition: all 0.3s ease;
}

.leaflet-control-zoom-in:hover,
.leaflet-control-zoom-out:hover {
  background: rgba(79, 70, 229, 0.1) !important;
  color: #4338ca !important;
}

/* Enhanced animations */
@keyframes bounce {
  0% {
    transform: rotate(-45deg) translateY(0);
  }
  50% {
    transform: rotate(-45deg) translateY(-12px);
  }
  100% {
    transform: rotate(-45deg) translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    transform: scale(0.95);
    opacity: 0.8;
  }
}

/* Marker hover effect */
.marker-pin:hover {
  transform: rotate(-45deg) scale(1.15);
  transition: all 0.3s ease;
  box-shadow: 0 8px 16px rgba(79, 70, 229, 0.5);
}

/* Additional hover effects */
.leaflet-marker-icon:hover {
  filter: brightness(1.1);
}

.leaflet-popup {
  transition: transform 0.3s ease;
}

.leaflet-popup:hover {
  transform: scale(1.02);
}

/* Add these styles for the checkbox */
.form-checkbox {
  @apply rounded border-gray-300;
  @apply text-indigo-600;
  @apply focus:ring-indigo-500;
  @apply cursor-pointer;
}

.form-checkbox:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

html,
body {
  height: 100%;
  overflow-y: auto;
  position: relative;
}

#root {
  min-height: 100%;
  overflow-y: auto;
}

.landing-page {
  position: relative;
  overflow-y: auto;
  height: auto;
}
